import React from 'react'
import SbEditable from 'storyblok-react'
import { Box, Typography, useMediaQuery, Divider } from '@material-ui/core'
import renderBloks from '@renderBloks'
import { makeStyles } from '@material-ui/styles'
import ACC_THEME from '@themes'
import { H6 } from '@system'

const useStyles = makeStyles((theme) => ({
  sidebarItemWithDescMenuLinksRoot: {
    margin: '0 24px 0 0',
    [theme.breakpoints.up('lg')]: {
      margin: '0 0 24px 0',
    },
  },
  heading: {
    fontSize: 11,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.iris,
    textTransform: 'uppercase',
    marginBottom: '4px',
  },
  description: {
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: theme.typography.fontWeightMedium,
    maxWidth: '209px',
  },
  dividerCta: {
    margin: '25px 0 25px 0',
    background: theme.palette.background.dark,
  },
  itemLinkParent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
  },
  itemLink: {
    '& a': {
      fontSize: '12px',
    },
  },
  itemLinkMobile: {
    marginBottom: '24px',
  },
}))

const TopNavLeftSidebarItemWithDescMenuLinks = (props) => {
  const classes = useStyles()
  const handHeldDevice = useMediaQuery(ACC_THEME.breakpoints.down('sm'))
  return (
    <SbEditable key={props.blok._uid} content={props.blok}>
      {!handHeldDevice ? (
        <Box className={classes.sidebarItemWithDescMenuLinksRoot}>
          <H6 className={classes.heading}>{props.blok.heading}</H6>
          {props.blok.hasDivider && <Divider className={classes.dividerCta} />}
          <Box className={classes.itemLinkParent}>
            <Box className={classes.itemLink}>
              {renderBloks(props.blok.itemLink)}
            </Box>
          </Box>

          <Typography className={classes.description}>
            {props.blok.description}
          </Typography>
        </Box>
      ) : (
        <Box className={classes.itemLinkMobile}>
          <H6 className={classes.heading}>{props.blok.heading}</H6>
          {renderBloks(props.blok.itemLink)}
        </Box>
      )}
    </SbEditable>
  )
}

export default TopNavLeftSidebarItemWithDescMenuLinks
